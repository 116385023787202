import { FeatherIcon } from '@/components/FeatherIcon'
import { StatusColorIndex } from '@/config/theme'
import { useTheme } from '@/store'
import { padding } from '@/utils/mixins'
import { css } from '@emotion/core'
import React, { useState } from 'react'

type Props = {
  id: string
  mqTwoColumns: string
}

const initialValues = {
  name: '',
  phone: '',
  email: '',
  message: '',
}

enum SubmissionStateTypes {
  Unsubmitted = 'UNSUBMITTED',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

const submissionStateMessage = {
  [SubmissionStateTypes.Success]: 'Thank you. We will get back to you soon.',
  [SubmissionStateTypes.Error]: 'Failed to send message. Please try again.',
}

const submissionStateIcon = {
  [SubmissionStateTypes.Success]: 'check',
  [SubmissionStateTypes.Error]: 'x',
}

type SubmissionStateThemeColor = {
  [SubmissionStateTypes.Success]: StatusColorIndex
  [SubmissionStateTypes.Error]: StatusColorIndex
}

const submissionStateThemeColor: SubmissionStateThemeColor = {
  [SubmissionStateTypes.Success]: 'success',
  [SubmissionStateTypes.Error]: 'error',
}

const initialSubmissionState = SubmissionStateTypes.Unsubmitted

function encode(data: any) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const FORM_NAME = 'contact'
const BOT_FIELD_NAME = 'bot-field'

export const ContactForm: React.FC<Props> = ({ id, mqTwoColumns }) => {
  const theme = useTheme()
  const [fieldValues, setFieldValues] = useState(initialValues)
  const [isFormSubmitting, setFormSubmitting] = useState(false)
  const [submissionState, setSubmissionState] = useState(initialSubmissionState)

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    const { name, value } = event.target

    setFieldValues(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setSubmissionState(SubmissionStateTypes.Unsubmitted)
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    setFormSubmitting(true)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': FORM_NAME, ...fieldValues }),
    })
      .then(() => {
        setFormSubmitting(false)
        setFieldValues(initialValues)
        setSubmissionState(SubmissionStateTypes.Success)
      })
      .catch(() => {
        setFormSubmitting(false)
        setSubmissionState(SubmissionStateTypes.Error)
      })
  }

  return (
    <section
      id={id}
      css={css`
        ${padding};

        ${mqTwoColumns} {
          padding: 0;
        }
      `}
    >
      <h2
        css={css`
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          align-items: center;
          grid-column-gap: 1rem;
          padding: 0 6.25%;

          color: ${theme.color.text_default};
          font-size: ${theme.size[2]};
          font-style: italic;
          font-weight: 400;

          &::before,
          &::after {
            content: '';
            height: 1px;
            background: ${theme.color.line};
          }
        `}
      >
        Or, surprise us with a message
      </h2>

      <form
        name={FORM_NAME}
        onSubmit={handleSubmit}
        data-netlify-honeypot={BOT_FIELD_NAME}
        data-netlify="true"
        css={css`
          margin-top: 2rem;
          padding: 6.25%;

          border: solid 1px ${theme.color.line};
          box-shadow: ${theme.elevation[2]};

          label {
            display: block;

            color: ${theme.color.text_lighter};

            &:not(:first-of-type) {
              margin-top: 2rem;
            }
          }

          input,
          textarea {
            -webkit-appearance: none;
            border-radius: 0;
            display: block;
            margin-top: 0.5rem;
            width: 100%;
            padding: 0.75rem 1rem;
            background: ${theme.color.bg_darker};
            border: solid 1px ${theme.color.line};

            color: ${theme.color.text_default};
            font-variant-numeric: lining-nums;

            &:focus {
              box-shadow: 0 0 0 3px ${theme.color.focus_outline};

              &::placeholder {
                color: ${theme.color.text_muted};
              }
            }

            &::placeholder {
              color: ${theme.color.text_muted};
            }

            &:-moz-focusring {
              outline: none;
            }

            transition: all 0.1s ease-out;
          }

          textarea {
            resize: none;
          }

          .form-honeypot {
            display: none;
          }

          button[type='submit'] {
            flex-shrink: 0;
            padding: 0.75rem 2rem;
            border: solid 1px ${theme.color.accent};
            background: ${theme.color.bg_default};
            cursor: pointer;

            font-weight: 700;
            color: ${theme.color.accent};

            &:hover {
              background: ${theme.color.accent};
              color: ${theme.color.text_on_accent_bg};
            }

            transition: all 0.1s ease-out;

            &:disabled {
              opacity: 0.2;
              cursor: not-allowed;

              background: transparent;
              color: ${theme.color.accent};
            }
          }
        `}
      >
        <p className="form-honeypot">
          <label htmlFor={BOT_FIELD_NAME}>
            Don’t fill this out:{' '}
            <input id={BOT_FIELD_NAME} name={BOT_FIELD_NAME} />
          </label>
        </p>

        <label htmlFor="name">
          Name <abbr title="required">*</abbr>
        </label>
        <input
          id="name"
          name="name"
          type="text"
          required={true}
          value={fieldValues.name}
          onChange={handleChange}
          placeholder="John Doe"
        />

        <label htmlFor="phone">Phone</label>
        <input
          id="phone"
          name="phone"
          type="phone"
          value={fieldValues.phone}
          onChange={handleChange}
          placeholder="04XX XXX XXX"
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={fieldValues.email}
          onChange={handleChange}
          placeholder="email@example.com"
        />

        <label htmlFor="message">
          Message <abbr title="required">*</abbr>
        </label>
        <textarea
          id="message"
          name="message"
          required={true}
          rows={8}
          spellCheck={false}
          value={fieldValues.message}
          onChange={handleChange}
          placeholder="Hello."
        />

        <div
          css={css`
            margin-top: 2rem;
            display: flex;
            align-items: center;
          `}
        >
          <button type="submit" disabled={isFormSubmitting}>
            Send
          </button>

          {submissionState !== SubmissionStateTypes.Unsubmitted && (
            <div
              css={css`
                display: flex;
                align-items: center;

                color: ${theme.color[
                  submissionStateThemeColor[submissionState]
                ]};

                svg {
                  flex-shrink: 0;
                  margin-left: 2rem;
                  margin-right: 0.5rem;
                }
              `}
            >
              <FeatherIcon name={submissionStateIcon[submissionState]} />
              <p>{submissionStateMessage[submissionState]}</p>
            </div>
          )}
        </div>
      </form>
    </section>
  )
}
