import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { SEO } from '@/components/SEO'
import { ContactForm } from '@/sections/ContactForm'
import { ContactHero } from '@/sections/ContactHero'
import { ContactInfo } from '@/sections/ContactInfo'
import { useTheme } from '@/store'
import { ContactPageQuery } from '@/types/__generated__/ContactPageQuery'
import { padding } from '@/utils/mixins'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'

export const query = graphql`
  query ContactPageQuery {
    contentfulPageContact {
      title
      metaDescription
    }
  }
`

type Props = {
  data: ContactPageQuery
}

const ContactPage: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const mqTwoColumns = theme.mq.md
  const { title, metaDescription } = data.contentfulPageContact

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumb>

      <ContactHero id="contact-hero" />

      <div
        css={css`
          ${mqTwoColumns} {
            ${padding};
          }
        `}
      >
        <div
          css={css`
            max-width: ${theme.maxWidth};
            margin: 0 auto;

            ${mqTwoColumns} {
              display: flex;
              align-items: center;

              #contact-info,
              #contact-form {
                width: 50%;
              }
            }
          `}
        >
          <ContactInfo id="contact-info" mqTwoColumns={mqTwoColumns} />
          <ContactForm id="contact-form" mqTwoColumns={mqTwoColumns} />
        </div>
      </div>
    </>
  )
}

export default ContactPage
