import { BrandDot } from '@/components/BrandDot'
import { useTheme } from '@/store'
import { ContactHeroQuery } from '@/types/__generated__/ContactHeroQuery'
import { fontSmoothing, padding } from '@/utils/mixins'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query ContactHeroQuery {
    contentfulPageContact {
      heroImage {
        fluid(maxWidth: 1024, quality: 95) {
          ...ContentfulFluid
        }
      }
      heroTitle
      heroSubtitle {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const ContactHero: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const data = useStaticQuery<ContactHeroQuery>(query)
  const { heroImage, heroTitle, heroSubtitle } = data.contentfulPageContact

  return (
    <section
      id={id}
      css={css`
        ${padding};
      `}
    >
      <div
        css={css`
          position: relative;
          max-width: ${theme.maxWidth};
          margin: 0 auto;

          border-radius: ${theme.radius.photo};
          overflow: hidden;
          box-shadow: ${theme.elevation[2]};
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            padding: 5%;
            padding-left: 10%;

            ${scrimGradient({
              color: theme.color.grays[8],
              direction: 'to left',
              startAlpha: 0.64,
            })};
          `}
        >
          <div
            css={css`
              max-width: 16rem;
              height: 100%;

              display: flex;
              flex-direction: column;
              justify-content: space-between;

              color: ${theme.color.grays[0]};
              text-align: right;
              text-shadow: ${theme.textShadow};
              ${fontSmoothing};
            `}
          >
            <h1
              css={css`
                ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
                font-weight: 700;
                line-height: 1.1;
              `}
            >
              {heroTitle}
              <BrandDot />
            </h1>
            <div
              css={css`
                ${fluidFontSize(16, 24, 320, theme.vwImageCapWidth)};
                line-height: 1.4;

                p + p {
                  margin-top: calc(32 / 24 * 1em);
                }
              `}
              dangerouslySetInnerHTML={{
                __html: heroSubtitle.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
        <Img fluid={heroImage.fluid} alt="" />
      </div>
    </section>
  )
}
