import { FeatherIcon } from '@/components/FeatherIcon'
import { useTheme } from '@/store'
import { ContactInfoQuery } from '@/types/__generated__/ContactInfoQuery'
import { padding } from '@/utils/mixins'
import { MailtoLink, createPhoneNumber } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

type Props = {
  id: string
  mqTwoColumns: string
}

const ICON_SIZE = 20

const query = graphql`
  query ContactInfoQuery {
    contentfulCompanyMetadata {
      name
      legalName
      email
      phone
      abn
      license
      operatingHours {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const ContactInfo: React.FC<Props> = ({ id, mqTwoColumns }) => {
  const theme = useTheme()
  const data = useStaticQuery<ContactInfoQuery>(query)
  const {
    name,
    legalName,
    email,
    phone,
    abn,
    license,
    operatingHours,
  } = data.contentfulCompanyMetadata

  return (
    <section
      id={id}
      css={css`
        ${padding};

        ${mqTwoColumns} {
          padding: 0;
        }
      `}
    >
      <div
        css={css`
          border-left: solid 1px ${theme.color.line};
          padding: 6.25%;

          color: ${theme.color.text_default};

          .company-names-wrapper {
            margin-left: calc(5% + ${ICON_SIZE}px);
          }

          .company-name {
            font-size: ${theme.size[6]};
          }

          .company-legal-name {
            margin-top: 0.5rem;

            font-size: ${theme.size[2]};
            font-style: italic;
          }
        `}
      >
        <div className="company-names-wrapper">
          <h2 className="company-name">{name}</h2>
          <p className="company-legal-name">{legalName}</p>
        </div>

        <dl
          css={css`
            line-height: 1.25;

            dt {
              margin-top: 4rem;
              display: flex;
              align-items: center;

              font-weight: 700;

              svg {
                margin-right: 5%;
                color: ${theme.color.text_lighter};
              }
            }

            dd {
              margin-top: 0.5rem;
              margin-left: calc(5% + ${ICON_SIZE}px);

              ul {
                list-style-type: none;
              }
            }

            a {
              color: inherit;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          `}
        >
          <dt>
            <FeatherIcon name="phone" size={ICON_SIZE} />
            <span>Phone</span>
          </dt>
          <dd>
            <a href={`tel:+${createPhoneNumber({ phone, countryCode: '61' })}`}>
              {phone}
            </a>
          </dd>

          <dt>
            <FeatherIcon name="mail" size={ICON_SIZE} />
            <span>Email</span>
          </dt>
          <dd>
            <MailtoLink email={email}>{email}</MailtoLink>
          </dd>

          <dt>
            <FeatherIcon name="clock" size={ICON_SIZE} />
            <span>Operating Hours</span>
          </dt>
          <dd
            dangerouslySetInnerHTML={{
              __html: operatingHours.childMarkdownRemark.html,
            }}
          />

          <dt>
            <FeatherIcon name="briefcase" size={ICON_SIZE} />
            <span>ABN</span>
          </dt>
          <dd>{abn}</dd>

          <dt>
            <FeatherIcon name="award" size={ICON_SIZE} />
            <span>License</span>
          </dt>
          <dd>{license}</dd>
        </dl>
      </div>
    </section>
  )
}
